
import {Options, Vue} from 'vue-class-component';
import HelloWorld from '@/components/HelloWorld.vue';
import FlipCard from "@/components/FlipCard.vue";
import VocabPanel from "@/components/VocabPanel.vue";
import {Pageable, VocFilter, VocService} from "@/service/VocService";
import {Language, User, Vocabulary, VocabularyIndex, VocabularyRange, VocabularyRangeStore} from "@/model/model";
import {UserService} from "@/service/UserService";
import VocabSelection from "@/components/VocabSelection.vue";
import {Watch} from "vue-property-decorator"; // @ is an alias to /src

@Options({
  components: {
    VocabSelection,
    VocabPanel,
    FlipCard,
    HelloWorld,
  },
})
export default class HomeView extends Vue {
  private newCount = 0;
  private rangeCount = 0;
  private language: Language | null = null;
  private user: User | null = null;

  private vocabRange: VocabularyRange = VocabularyRangeStore.defaults();
  private vocabSelectionConfig: any = null;

  mounted() {
    this.vocabRange = VocabularyRangeStore.load(this.$route.params.langUuid as string);

    VocService.get().getLanguage(this.$route.params.langUuid as string).then(lang => this.language = lang ? lang : null);
    VocService.get().getNewVocabularyCount(this.$route.params.langUuid as string, this.vocabRange)
        .then(count => this.newCount = count);
    UserService.get().getUser().then(user => this.user = user);
  }

  vocabForRangeSelected(which: 'from' | 'to', vocab: Vocabulary) {
    if (typeof(vocab) === 'undefined') {
      this.vocabSelectionConfig = null;
      return;
    }

    let index = VocabularyRangeStore.defaults()[which] as VocabularyIndex;
    if (vocab && vocab.rangeindex) {
      index = {
        label: vocab.text_lang1.split(',')[0],
        rangeindex: vocab.rangeindex
      } as VocabularyIndex;
    }
    this.vocabRange[which] = index;
    VocabularyRangeStore.store(this.$route.params.langUuid as string, this.vocabRange);
    this.vocabSelectionConfig = null;
  }

  selectRangeFrom() {
    const isRangeActive = this.vocabRange?.from && this.vocabRange?.from?.rangeindex != VocabularyRangeStore.defaults().from.rangeindex;
    this.vocabSelectionConfig = {
      searchTextPrefill: isRangeActive ? this.vocabRange?.from?.label : '',
      label: "Abfragebereich Beginn",
      onClose: (vocab: any) => this.vocabForRangeSelected('from', vocab)
    }
  }

  selectRangeTo() {
    const isRangeActive = this.vocabRange?.to && this.vocabRange?.to?.rangeindex != VocabularyRangeStore.defaults().to.rangeindex;
    this.vocabSelectionConfig = {
      searchTextPrefill: isRangeActive ? this.vocabRange?.to?.label : '',
      label: "Abfragebereich Ende",
      onClose: (vocab: any) => this.vocabForRangeSelected('to', vocab)
    }
  }

  @Watch('vocabRange.to', {immediate: true})
  @Watch('vocabRange.from', {immediate: true})
  private onVocabRangeChanged() {
    VocService.get().getVocabularyPaged(this.$route.params.langUuid as string,
        {
          text: '',
          range: this.vocabRange
        } as VocFilter,
        {page: 0, limit: 1} as Pageable)
        .then(page => this.rangeCount = page.totalElements);
  }
}
